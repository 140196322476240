import { render, staticRenderFns } from "./ChatRoom.vue?vue&type=template&id=adbead20&scoped=true"
import script from "./ChatRoom.vue?vue&type=script&lang=js"
export * from "./ChatRoom.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/semidark-style-1.min.css?vue&type=style&index=0&id=adbead20&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "adbead20",
  null
  
)

export default component.exports